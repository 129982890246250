<template>
  <div>
    <project-documents-list-loader v-if="loading" />
    <template v-else-if="documents.length > 0">
      <th-card
        class="th-px-24 project-document"
        body-classes="th-p-12"
        v-for="(document, docKey) in documents || []"
        :key="docKey"
      >
        <div
          class="project-document__item th-py-16 d-flex align-items-center justify-content-between"
          :class="{ 'project-document__item--disabled': disabled }"
        >
          <div
            class="d-flex flex-column flex-md-row align-items-center flex-grow-1"
          >
            <div class="th-mr-12">
              <v-img
                :src="require('@/assets/images/document.svg')"
                :alt="document.name"
                height="32"
                width="32"
              />
            </div>
            <h6 class="subtitle2 th-m-0" v-html="document.name"></h6>
          </div>
          <v-btn
            color="primary"
            outlined
            :to="manageDocument(document.slug)"
            :disabled="disabled"
          >
            {{ $translation.t("Manage") }}
          </v-btn>
        </div>
      </th-card>
    </template>
    <slot name="noDocuments" v-else />
  </div>
</template>

<script>
import ThCard from "../GeneralComponents/ThCard";
import ProjectDocumentsListLoader from "./ProjectDocumentsListLoader";
export default {
  name: "ProjectDocumentsList",
  components: { ProjectDocumentsListLoader, ThCard },
  props: {
    documents: {
      type: Array,
      default: () => [],
    },
    disabled: Boolean,
    loading: Boolean,
  },
  methods: {
    manageDocument(slug) {
      return {
        name: "project_document_index",
        params: {
          ...this.$route.params,
          slug,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.project-document {
  &:not(:first-child) {
    margin-top: 12px;
  }

  &__item {
    &--disabled {
      filter: grayscale(100%);
      opacity: 0.6;
    }
  }
}
</style>
