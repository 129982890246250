<template>
  <div>
    <th-card
      class="th-px-24 project-document"
      body-classes="th-p-12"
      v-for="docKey in documents"
      :key="docKey"
    >
      <div
        class="project-document__item th-py-16 d-flex align-items-center justify-content-between"
      >
        <div
          class="d-flex flex-column flex-md-row align-items-center flex-grow-1"
        >
          <div class="th-mr-12">
            <v-skeleton-loader type="avatar" />
          </div>
          <v-skeleton-loader
            class="flex-grow-1"
            type="heading"
            min-width="360"
          />
        </div>
        <v-skeleton-loader type="button" />
      </div>
    </th-card>
  </div>
</template>

<script>
import ThCard from "../GeneralComponents/ThCard";
export default {
  name: "ProjectDocumentsListLoader",
  components: { ThCard },
  props: {
    documents: {
      type: Number,
      default: 2,
    },
  },
};
</script>

<style lang="scss" scoped>
.project-document {
  &:not(:first-child) {
    margin-top: 12px;
  }
}
</style>
