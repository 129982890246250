<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-8">
        <h6>{{ $translation.t("Documents") }}</h6>
        <project-documents-list
          :documents="documents"
          :disabled="!!questionnaireInProgress"
          :loading="loading"
        >
          <template v-slot:noDocuments v-if="questionnaireInProgress">
            <h6 class="body2 text-center th-mt-24 th-mb-16">
              {{
                $translation.t("documents.questionnaire.in.progress.warning")
              }}
            </h6>
            <div class="text-center">
              <v-btn
                color="primary"
                :to="{
                  name: 'welcome_questionnaire',
                  params: {
                    ...$route.params,
                    type: questionnaireInProgress.type,
                  },
                }"
              >
                {{ $translation.t("continue to questionnaire") }}
              </v-btn>
            </div>
          </template>
        </project-documents-list>
      </div>
      <div class="col-12 col-md-4">
        <project-progress-column :progress="progress" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import ProjectDocumentsList from "../components/Project/ProjectDocumentsList";
import ProjectProgressColumn from "../components/Project/ProjectProgressColumn";

export default {
  name: "Home",
  components: {
    ProjectProgressColumn,
    ProjectDocumentsList,
  },
  data() {
    return {
      companyInformation: {},
      progress: [],
      documents: [],
      loading: false,
    };
  },
  async beforeCreate() {
    this.loading = true;
    this.companyInformation = await this.$axios.get("/api/v1/business/data");

    const business = await this.$axios.get("/api/v1/business");

    this.progress = business?.progress || [];
    this.documents = business?.documents || [];

    this.SET_PAGE_TITLE({
      routeFullPath: this.$route.fullPath,
      title: this.companyInformation.company_name || "",
    });
    this.loading = false;
  },
  computed: {
    questionnaireInProgress() {
      return this.progress.find(({ exists }) => exists !== true);
    },
  },
  methods: {
    ...mapMutations(["SET_PAGE_TITLE"]),
  },
};
</script>

<style lang="scss" scoped></style>
