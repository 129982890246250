<template>
  <th-card class="project-progress">
    <h6>
      {{ $translation.t("Data") }}
    </h6>
    <!--    <project-progress :project="project" class="th-mt-24" />-->
    <div class="th-mt-16">
      <div
        class="project-progress__document th-mt-8 text-decoration-none d-flex flex-column flex-md-row align-items-center"
        v-for="(step, sKey) in progress"
        :key="sKey"
      >
        <div class="flex-grow-1 d-flex align-items-center">
          <v-icon v-if="step.exists" color="success" size="24" class="th-mr-8">
            mdi-checkbox-marked-circle
          </v-icon>
          <v-icon v-else color="primary" size="24" class="th-mr-8">
            mdi-plus-circle
          </v-icon>
          <h6 class="body2 th-m-0">
            {{ $translation.t(`questionnaire.progress.${step.type}`) }}
          </h6>
        </div>
        <div class="th-ml-8">
          <v-btn
            color="primary"
            text
            x-small
            :to="questionnaireStep(step.type)"
          >
            {{ step.exists ? $translation.t("Edit") : $translation.t("Fill") }}
          </v-btn>
        </div>
      </div>
      <page-loader size="40" class="th-py-32" v-if="progress.length <= 0" />
    </div>
  </th-card>
</template>

<script>
// import ProjectProgress from "@/components/Projects/Project/ProjectProgress";

import ThCard from "../GeneralComponents/ThCard";
import PageLoader from "../PageLoader";
export default {
  name: "ProjectProgressColumn",
  components: {
    PageLoader,
    ThCard,
    // ProjectProgress,
  },
  props: {
    progress: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    questionnaireStep(type) {
      return {
        name: `questionnaire_type`,
        params: {
          ...this.$route.params,
          type,
        },
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.project-progress {
  &__document {
    padding: 8px 12px;
    border: 1px solid $border-color;
    border-radius: 8px;
  }
}
</style>
